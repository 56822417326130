import React from 'react';
import clsx from 'clsx';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    base?: boolean;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
    ({ base, children, className, ...props }, ref) => {
        const classes = clsx(
            'card flex flex-col',
            { rounded: base },
            className
        );

        return (
            <div className={classes} ref={ref} {...props}>
                {children}
            </div>
        );
    }
);

Card.defaultProps = {
    base: true
};

export default Card;
