import React, { FC, useRef } from 'react';
import Slider from 'react-slick';

import { PartnerLogo } from '../../../components/partner-logo';
import { Box } from '../../../components/wrapper/box';
import { SlickDot } from '../slick-dot';
import { SlickNav } from '../slick-nav';

interface PartnerSliderProps {
    partners: any;
}

const PartnerSlider: FC<PartnerSliderProps> = ({ partners }) => {
    const slider = useRef(null);
    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        rows: 2,
        appendDots: (dots: any) => <SlickNav dots={dots} slider={slider} />,
        customPaging: () => <SlickDot />,
        responsive: [
            {
                breakpoint: 1367, // 1024 to 1366 //buggy
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    rows: 2
                }
            },
            {
                breakpoint: 1023, // 768 to 1023
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    rows: 2
                }
            },
            {
                breakpoint: 767, // bottom to 767
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    rows: 1
                }
            }
        ]
    };

    return (
        <Slider {...settings} ref={slider}>
            {partners.map(
                (
                    item: { file: { url: string }; description: string },
                    i: number
                ) => {
                    return (
                        <div key={`partners-${i}`}>
                            <Box
                                ratio="1:1"
                                className="border-divider-light border">
                                <PartnerLogo
                                    src={item.file.url}
                                    alt={item.description}
                                />
                            </Box>
                        </div>
                    );
                }
            )}
        </Slider>
    );
};

export default PartnerSlider;
