import clsx from 'clsx';
import React, { FC, useRef } from 'react';
import Slider from 'react-slick';

import { Button } from '../../../components/navigation/button';
import { Paragraph } from '../../../components/typography/paragraph';
import { Card } from '../../../components/wrapper/card';
import { ProductShowcase } from '../../product/product-showcase';
import { SlickDot } from '../slick-dot';
import { SlickNav } from '../slick-nav';

interface ProductSliderProps {
    products: any;
}

const ProductSlider: FC<ProductSliderProps> = ({ products }) => {
    const slider = useRef(null);
    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        appendDots: (dots: any) => <SlickNav dots={dots} slider={slider} />,
        customPaging: () => <SlickDot />,
        responsive: [
            {
                breakpoint: 1367, // 1024 to 1366 //buggy
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 1023, // 768 to 1023
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767, // bottom to 767
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Slider className={clsx('mt-5 t-sm:mt-10')} {...settings} ref={slider}>
            {products.map(
                (item: {
                    id: string;
                    title: string;
                    slug: string;
                    featuredImage: {
                        file: { url: string };
                        description: string;
                    };
                }) => {
                    return (
                        <div key={item.id}>
                            <Card className={clsx('bg-light-gray p-6')}>
                                <ProductShowcase
                                    img={
                                        item.featuredImage &&
                                        item.featuredImage.file.url
                                    }
                                    alt={
                                        item.featuredImage &&
                                        item.featuredImage.description
                                    }
                                />
                                <Paragraph
                                    className="font-medium text-center mt-5 min-h-55px"
                                    body={false}>
                                    {item.title}
                                </Paragraph>

                                <Button
                                    to={`/products${item.slug}`}
                                    variant="text"
                                    className="mt-5 mx-auto"
                                    onProductShowcase={true}>
                                    More
                                </Button>
                            </Card>
                        </div>
                    );
                }
            )}
        </Slider>
    );
};

export default ProductSlider;
