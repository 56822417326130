import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Image } from '../lib/components/image';
import { Container } from '../lib/components/layout/container';
import { Grid } from '../lib/components/layout/grid';
import { Section } from '../lib/components/layout/section';
import { Button } from '../lib/components/navigation/button';
import { SEO } from '../lib/components/seo';
import { Heading } from '../lib/components/typography/heading';
import { Paragraph } from '../lib/components/typography/paragraph';
import { Box } from '../lib/components/wrapper/box';
import { Card } from '../lib/components/wrapper/card';
import { BaseLayout } from '../lib/ui/base-layout';
import { ProductShowcase } from '../lib/ui/product/product-showcase';
import { RichText } from '../lib/ui/rich-text';
import { PartnerSlider } from '../lib/ui/slick-slider/partner-slider';
import { ProductSlider } from '../lib/ui/slick-slider/product-slider';

export default function Home() {
    const {
        pageContent,
        heroText,
        productShowcase,
        ourProductsText,
        productSubCategoryShowcase,
        weNeverCompromise,
        partneringAcrossText,
        partners,
        upgradingTheStandardText,
        coverageNumbers,
        upgradingTheStandardImage,
        legacyOfTrust_1,
        legacyOfTrust_2,
        legacyOfTrust_3
    } = useStaticQuery(query);

    const content = {
        hero: {
            text: heroText,
            productShowcase: productShowcase
        },
        ourProducts: {
            text: ourProductsText,
            productShowcase: productSubCategoryShowcase
        },
        weNeverCompromise: {
            text: weNeverCompromise.content[0],
            images: weNeverCompromise.images
        },
        partneringAcross: {
            text: partneringAcrossText,
            images: partners.partners
        },
        upgradingTheStandard: {
            text: upgradingTheStandardText,
            coverageNumbers: coverageNumbers,
            images: upgradingTheStandardImage.images[0]
        },
        legacyOfTrust: {
            text_1: legacyOfTrust_1,
            text_2: legacyOfTrust_2,
            text_3: legacyOfTrust_3
        }
    };

    return (
        <>
            <SEO
                title={pageContent.seo.title}
                description={pageContent.seo.description}
                image={pageContent.seo.image.file.url}
            />
            <BaseLayout>
                <Section
                    base={false}
                    className={clsx(
                        'bg-primary rounded-b-md pt-8 pb-16',
                        't-lg:pt-10 t-lg:pb-20'
                    )}>
                    <Container>
                        <Grid
                            container
                            defaultGap={false}
                            className={clsx(
                                't-lg:gap-x-10 t-lg:items-center',
                                'l-lg:gap-x-2'
                            )}>
                            <Heading
                                tagName="h1"
                                color="white"
                                className={clsx(
                                    't-sm:col-span-full',
                                    't-lg:col-span-6 tl:max-w-21ch'
                                )}>
                                {content.hero.text.title}
                            </Heading>
                            <RichText
                                color="white"
                                data={content.hero.text.richText}
                                className={clsx(
                                    't-sm:col-span-full',
                                    't-lg:col-span-6',
                                    'l-lg:col-span-8-13',
                                    'd-md:col-span-9-13'
                                )}
                            />
                        </Grid>

                        <Grid
                            container
                            base={false}
                            className={clsx(
                                'mt-5',
                                't-sm:grid-cols-2 t-sm:mt-10',
                                't-lg:grid-cols-3'
                            )}>
                            {content.hero.productShowcase.content.map(
                                (item: {
                                    id: string;
                                    title: string;
                                    slug: string;
                                    image: {
                                        file: { url: string };
                                        description: string;
                                    };
                                }) => {
                                    return (
                                        <Grid item key={item.id}>
                                            <Card className="bg-primary-100 px-4 pt-4 pb-7 relative">
                                                <Heading
                                                    tagName="h5"
                                                    color="white"
                                                    className={clsx(
                                                        `${
                                                            item.title ===
                                                            'Medical Disposables'
                                                                ? 'max-w-15ch'
                                                                : 'max-w-20ch'
                                                        }`,
                                                        't-sm:absolute'
                                                    )}>
                                                    {item.title}
                                                </Heading>

                                                <ProductShowcase
                                                    img={item.image.file.url}
                                                    size="lg"
                                                    color="bg-primary-200"
                                                    className={clsx(
                                                        'mt-3',
                                                        't-sm:mt-12'
                                                    )}
                                                    alt={item.image.description}
                                                />

                                                <Button
                                                    to={item.slug}
                                                    className="mt-5 mx-auto"
                                                    onProductShowcase={true}>
                                                    View Products
                                                </Button>
                                            </Card>
                                        </Grid>
                                    );
                                }
                            )}
                        </Grid>
                    </Container>
                </Section>

                {/* --- */}

                <Section>
                    <Container>
                        <Grid
                            container
                            defaultGap={false}
                            className={clsx('t-sm:gap-2')}>
                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-start-1 t-sm:col-end-7',
                                    'd-sm:col-end-8'
                                )}>
                                <Heading className={clsx('t-sm:mb-0')}>
                                    {content.ourProducts.text.title}
                                </Heading>
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-start-7 t-sm:col-end-13',
                                    'd-sm:col-start-8'
                                )}>
                                <RichText
                                    data={content.ourProducts.text.richText}
                                />
                            </Grid>
                        </Grid>

                        <ProductSlider
                            products={
                                content.ourProducts.productShowcase.content
                            }
                        />
                    </Container>
                </Section>

                {/* --- */}

                <Section framed className="bg-light-gray">
                    <Container>
                        <Grid container>
                            {content.weNeverCompromise.images.map(
                                (
                                    item: {
                                        id: string;
                                        file: { url: string };
                                        description: string;
                                    },
                                    i: number
                                ) => {
                                    return (
                                        <Grid
                                            key={item.id}
                                            item
                                            className={clsx(
                                                {
                                                    't-sm:col-span-1-7 t-lg:col-span-1-4':
                                                        i === 0
                                                },
                                                {
                                                    't-sm:mt-10 t-sm:col-span-7-13 t-lg:col-span-4-7':
                                                        i === 1
                                                }
                                            )}>
                                            <Image
                                                className="h-54 min-h-30"
                                                src={item.file.url}
                                                alt={item.description}
                                            />
                                        </Grid>
                                    );
                                }
                            )}

                            <Grid
                                item
                                className={clsx(
                                    'order-first t-lg:order-none',
                                    't-sm:col-span-full',
                                    't-lg:col-span-7-13 t-lg:pl-10 t-lg:self-center',
                                    'd-sm:col-span-8-13 d-sm:pl-0'
                                )}>
                                <Heading>
                                    {content.weNeverCompromise.text.title}
                                </Heading>

                                <RichText
                                    data={
                                        content.weNeverCompromise.text.richText
                                    }
                                />

                                <Button className="mt-2 t-sm:mt-4" to="/about">
                                    Learn More
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Section>

                {/* --- */}

                <Section>
                    <Container>
                        <Grid container className="items-center grid-cols-full">
                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-1-7 t-sm:pr-10',
                                    't-lg:col-span-1-6',
                                    'd-sm:col-span-1-5'
                                )}>
                                <Heading className="max-w-17ch">
                                    {content.partneringAcross.text.title}
                                </Heading>

                                <RichText
                                    data={
                                        content.partneringAcross.text.richText
                                    }
                                />

                                <Button
                                    className="mt-2 t-sm:mt-4"
                                    to="/contact">
                                    Be a global partner
                                </Button>
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-7-13',
                                    't-lg:col-span-6-13',
                                    'd-sm:col-span-5-13'
                                )}>
                                <PartnerSlider
                                    partners={content.partneringAcross.images}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Section>

                {/* --- */}

                <Section
                    base={false}
                    framed
                    className="bg-primary py-16 t-lg:py-12">
                    <Container>
                        <Grid container className="items-center">
                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-1-7 t-sm:pr-10',
                                    'l-sm:col-span-1-6'
                                )}>
                                <Heading color="white">
                                    {content.upgradingTheStandard.text.title}
                                </Heading>

                                <RichText
                                    data={
                                        content.upgradingTheStandard.text
                                            .richText
                                    }
                                    color="white"
                                />

                                <Button
                                    className="mt-2 t-sm:mt-4"
                                    to="/contact">
                                    Be a dealer
                                </Button>
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-7-13',
                                    'l-sm:col-span-6-13'
                                )}>
                                <Box className="bg-primary-100 w-28 min-w-20 relative z-20">
                                    <Paragraph
                                        className="text-xl text-center"
                                        color="white"
                                        body={false}>
                                        {
                                            content.upgradingTheStandard
                                                .coverageNumbers.edges[1].node
                                                .total
                                        }
                                    </Paragraph>
                                    <Paragraph
                                        className="text-center"
                                        color="white">
                                        International & Local Partners
                                    </Paragraph>
                                </Box>

                                <Image
                                    src={
                                        content.upgradingTheStandard.images.file
                                            .url
                                    }
                                    alt={
                                        content.upgradingTheStandard.images
                                            .description
                                    }
                                    className={clsx(
                                        'h-58 w-4/5 mx-auto -my-10',
                                        't-lg:-my-15',
                                        'd-sm:w-3/4'
                                    )}
                                />

                                <Box className="bg-primary-100 w-28 min-w-20 relative z-20 ml-auto">
                                    <Paragraph
                                        className="text-center"
                                        color="white">
                                        Serving
                                    </Paragraph>
                                    <Paragraph
                                        className="text-xl text-center"
                                        color="white"
                                        body={false}>
                                        {
                                            content.upgradingTheStandard
                                                .coverageNumbers.edges[0].node
                                                .total
                                        }
                                    </Paragraph>
                                    <Paragraph
                                        className="text-center"
                                        color="white">
                                        Hospitals
                                    </Paragraph>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Section>

                {/* --- */}

                <Section>
                    <Container>
                        <Heading className="t-sm:mb-0">
                            {content.legacyOfTrust.text_1.title}
                        </Heading>

                        <Grid
                            container
                            defaultGap={false}
                            className="gap-y-10 gap-x-10 t-sm:mt-4">
                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-6',
                                    't-lg:col-span-4'
                                )}>
                                <RichText
                                    data={content.legacyOfTrust.text_1.richText}
                                />
                                <Button
                                    variant="text"
                                    className="mt-3"
                                    to="/contact">
                                    Our story
                                </Button>
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-6',
                                    't-lg:col-span-4'
                                )}>
                                <RichText
                                    data={content.legacyOfTrust.text_2.richText}
                                />

                                <Button
                                    variant="text"
                                    className="mt-3"
                                    to="/contact">
                                    Join our team
                                </Button>
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    't-sm:col-span-6',
                                    't-lg:col-span-4'
                                )}>
                                <RichText
                                    data={content.legacyOfTrust.text_3.richText}
                                />

                                <Button
                                    variant="text"
                                    className="mt-3"
                                    anchor="product-demo">
                                    Product demo
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Section>
            </BaseLayout>
        </>
    );
}

const query = graphql`
    {
        pageContent: contentfulPages(slug: { eq: "/" }) {
            seo {
                title
                description
                image {
                    file {
                        url
                    }
                }
            }
        }
        heroText: contentfulComponentText(
            identifier: { eq: "home-hero-text" }
        ) {
            id
            title
            richText {
                raw
            }
        }
        productShowcase: contentfulComponentBlocks(
            identifier: { eq: "product-showcase-category" }
        ) {
            id
            content {
                ... on ContentfulProductsShowcaseCategory {
                    id
                    title
                    slug
                    image {
                        file {
                            url
                        }
                        description
                    }
                }
            }
        }
        ourProductsText: contentfulComponentText(
            identifier: { eq: "home-our-products-text" }
        ) {
            id
            title
            richText {
                raw
            }
        }
        productSubCategoryShowcase: contentfulComponentBlocks(
            identifier: { eq: "products-showcase-sub-category" }
        ) {
            content {
                ... on ContentfulProductsPageSubCategory {
                    id
                    title
                    slug
                    featuredImage {
                        file {
                            url
                        }
                        description
                    }
                }
            }
        }
        weNeverCompromise: contentfulComponentSection(
            identifier: { eq: "home-we-never-compromise-section" }
        ) {
            content {
                ... on ContentfulComponentText {
                    id
                    title
                    richText {
                        raw
                    }
                }
            }
            images {
                id
                file {
                    url
                }
                description
            }
        }
        partneringAcrossText: contentfulComponentText(
            identifier: { eq: "home-partnering-across" }
        ) {
            id
            title
            richText {
                raw
            }
        }
        partners: contentfulPartners {
            partners {
                file {
                    url
                }
                description
            }
        }
        upgradingTheStandardText: contentfulComponentText(
            identifier: { eq: "upgrading-the-standard" }
        ) {
            title
            richText {
                raw
            }
        }
        coverageNumbers: allContentfulComponentCoverageNumbers(
            filter: { identifier: { ne: "mm-number-of-covered-hospitals" } }
            sort: { fields: identifier }
        ) {
            edges {
                node {
                    total
                }
            }
        }
        upgradingTheStandardImage: contentfulComponentSection(
            identifier: { eq: "home-upgrading-the-standard-section" }
        ) {
            images {
                file {
                    url
                }
                description
            }
        }
        legacyOfTrust_1: contentfulComponentText(
            identifier: { eq: "a-legacy-of-trust-1" }
        ) {
            title
            richText {
                raw
            }
        }
        legacyOfTrust_2: contentfulComponentText(
            identifier: { eq: "a-legacy-of-trust-2" }
        ) {
            richText {
                raw
            }
        }
        legacyOfTrust_3: contentfulComponentText(
            identifier: { eq: "a-legacy-of-trust-3" }
        ) {
            richText {
                raw
            }
        }
    }
`;
