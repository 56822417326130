import clsx from 'clsx';
import React from 'react';

export interface ImageProps extends React.HTMLAttributes<HTMLDivElement> {
    rounded?: 'sm' | 'md' | 'lg' | '' | 'full';
    src: string;
    alt: string;
}

const Image = React.forwardRef<HTMLDivElement, ImageProps>(
    ({ alt, className, src, rounded, ...props }, ref) => {
        const classes = clsx(
            'relative z-10 overflow-hidden',
            rounded !== '' ? `rounded-${rounded}` : 'rounded',
            className
        );

        return (
            <div className={classes} ref={ref} {...props}>
                <img
                    src={src}
                    alt={alt}
                    className={clsx(
                        'absolute inset-0 w-full h-full object-cover object-center'
                    )}
                />
            </div>
        );
    }
);

Image.defaultProps = {
    rounded: '',
    alt: ''
};

export default Image;
